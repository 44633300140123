var Glory = {
	user			:false,
	isLoggedIn		:false,

	init : function (){
		this.user = true
		this.isLoggedIn = true
	},

	counter : function (date, url=null, past=null) {
		var d = new Date(rails_date);
		var countdown_string = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear() + ' ' + d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();
	  	$('#counter').countdown(countdown_string, function(event) {
        if(event.strftime('%D') == '00' && event.strftime('%H') == '00' && event.strftime('%M') == '00'){
          if(past == true){
            $('#counter').html("<a class='btn event-btn btn-inline event-btn-now-live pt-1 px-3' href='" + url + "'><i class='fa fa-stream pr-2'></i> Watch Full Replay</a>");
          } else {
            $('#counter').html("<a class='btn event-btn btn-inline event-btn-now-live pt-1 px-3' href='" + url + "'><i class='fa fa-stream pr-2'></i><span class='counter-now-live'>NOW LIVE</span></a>");
          }
        } else {
          //var myzone = moment.tz.guess();
          //var date = moment(event.finalDate);
          //var time_with_zone = date.tz(myzone);
          //console.log(time_with_zone);
          //console.log(event);
          //console.log(time_with_zone.format('D'));
          //console.log(event.strftime('%D'));
          //console.log(moment.duration(time_with_zone.diff(moment().tz(myzone))));
			
    			$('#days').html(event.strftime('%D'));
    			$('#hours').html(event.strftime('%H'));
    			$('#mins').html(event.strftime('%M'));
        }
		  });
	  },

	formatTime : function(date) {
		var d = new Date(date);
  		var hours = d.getHours();
  		var minutes = d.getMinutes();
  		var ampm = hours >= 12 ? 'pm' : 'am';
  		hours = hours % 12;
  		hours = hours ? hours : 12; // the hour '0' should be '12'
  		minutes = minutes < 10 ? '0'+minutes : minutes;
  		var strTime = hours + ':' + minutes + ' ' + ampm;
  		return strTime;
	},
	//TRANSLATIONS
	// requires data attributes 
		//data-translate = true
		//data-locale - the locale to translate to
		//data-exclude - any locales to exclude from translation.
	//Example: <span data-translate="true" data-locale="<%= I18n.locale.to_s %>" data-exclude="en">Hello World!</span> 
	loadTranslations : function(){
		$("[data-translate='true']").each(function(){
			var $this = $(this);
			var text = $.trim($this.html());
			var locale = $this.data('locale');
			var exclude = $this.data('exclude').split(',');
			// due to caching we need to pull token from cookie and insert into head
			var AUTH_TOKEN = Cookies.get('csrftoken', { secure: true });
			  // alert(AUTH_TOKEN);

			if (!exclude.includes(locale)) {
				//use this plus each loop to limit the size of translations google wants less than 5000 characters per
				//text_array = text.match(/\S[\s\S]{0,4900}\S(?=\s|$)/g);
				//text_array = text.split(4900);
				
				if (locale.indexOf("es") >= 0) {
					var translating = "Traducción";
				} else if (locale.indexOf("fr") >= 0) {
					var translating = "Traduction en cours";
				} else if (locale.indexOf("pt") >= 0) {
					var translating = "Traduzindo";
				} else if (locale.indexOf("nl") >= 0) {
					var translating = "Translating";
				} else if (locale.indexOf("zh") >= 0) {
					var translating = "Translating";
				} else if (locale.indexOf("ja") >= 0) {
					var translating = "Translating";
				  	var powered = "";
				}
				//remove default html and replace with translating pacifier
				$this.html('<span class="translating">' + translating + '</span>');

				//loop through each  split translation
				//$.each( text_array, function( key, text ) {
					var TranslationEndpoint = '/translations.json';
		  			$.ajax({
						url: TranslationEndpoint,
						type: 'POST',
						data: {translation: {locale: locale, text: encodeURIComponent(text)}, authenticity_token: AUTH_TOKEN},
						error: function (xhr, status, error) {
							//log error and return existing html, possibly add a h1 msg saying translation failed
							console.dir("TRANSLATE ERROR === " + error);
			  				$this.append(text);
						}
					})
					.done(function(data) {
						$("span").remove(".translating");
						//toggle data attribute so we dont re-translate on ajax calls.
						$this.removeAttr( "data-translate", 'false' );
						//append translated text
						$this.html(data.text);
						console.log('*** translation complete ***');
					});
				//});
			}
			
		});
	},

	loadLongTranslations : function(){
		$("[data-longtranslate='true']").each(function(){
			var $this = $(this);
			var text = $.trim($this.html());
			var locale = $this.data('locale');
			var exclude = $this.data('exclude').split(',');
			// due to caching we need to pull token from cookie and insert into head
			var AUTH_TOKEN = Cookies.get('csrftoken', { secure: true });
			// alert(AUTH_TOKEN);

			if (!exclude.includes(locale)) {
				//use this plus each loop to limit the size of translations google wants less than 5000 characters per
				text_array = text.split(4900);

				if (locale.indexOf("es") >= 0) {
					var translating = "Traducción";
				} else if (locale.indexOf("fr") >= 0) {
					var translating = "Traduction en cours";
				} else if (locale.indexOf("pt") >= 0) {
					var translating = "Traduzindo";
				} else if (locale.indexOf("nl") >= 0) {
					var translating = "Traduzindo";
				} else if (locale.indexOf("zh") >= 0) {
					var translating = "Translating";
				} else if (locale.indexOf("ja") >= 0) {
					var translating = "Translating";
			  	var powered = "";
				}
				//remove default html and replace with translating pacifier
				$this.html('<span class="translating">' + translating + '</span>');

				//loop through each  split translation
				$.each( text_array, function( key, text ) {
					var TranslationEndpoint = '/translations.json';
		  			$.ajax({
						url: TranslationEndpoint,
						type: 'POST',
						data: {translation: {locale: locale, text: encodeURIComponent(text)}, authenticity_token: AUTH_TOKEN},
						error: function (xhr, status, error) {
							//log error and return existing html, possibly add a h1 msg saying translation failed
							console.dir(error);
			  				$this.html(text);
						}
					})
					.done(function(data) {
						$("span").remove(".translating");
						//toggle data attribute so we dont re-translate on ajax calls.
						$this.removeAttr( "data-translate", 'false' );
						//append translated text
						$this.append(data.text);
						console.log('*** translation complete ***');
					});
				});

			}
		});
	},
}
Glory.init();
global.Glory = Glory;